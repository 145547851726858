import { LoggedOutGuard } from './@core/gaurds/logged-out/logged-out.guard';
import { LoggedInGuard } from './@core/gaurds/logged-in/logged-in.guard';
import { LayoutComponent } from './@core/layout/layout.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SystemType } from './@shared/enums/system-type.enum';

const routes: Routes = [
    {
        path: '',
        redirectTo: 'home',
        pathMatch: 'prefix'
    },
    {
        path: 'login',
        pathMatch: 'full',
        redirectTo: 'client'
    },
    {
        path: 'admin',
        loadChildren: () => import('./@features/auth/auth.module').then(m => m.AuthModule),
        data: { userType: SystemType.Admin },
        canActivate: [LoggedOutGuard]
    },
    {
        path: 'client',
        loadChildren: () => import('./@features/auth/auth.module').then(m => m.AuthModule),
        data: { userType: SystemType.Client },
        canActivate: [LoggedOutGuard]
    },
    {
        path: 'maintenance',
        loadChildren: () => import('./@features/auth/auth.module').then(m => m.AuthModule),
        data: { userType: SystemType.Maintenance },
        canActivate: [LoggedOutGuard]
    },
    {
        path: 'facilityManagement',
        loadChildren: () => import('./@features/auth/auth.module').then(m => m.AuthModule),
        data: { userType: SystemType.Facility },
        canActivate: [LoggedOutGuard]
    },
    {
        path: 'insurance',
        loadChildren: () => import('./@features/auth/auth.module').then(m => m.AuthModule),
        data: { userType: SystemType.Insurance },
        canActivate: [LoggedOutGuard]
    },
    {
        path: '',
        component: LayoutComponent,
        children: [
            {
                path: '',
                loadChildren: () => import('./@features/home/home.module').then(m => m.HomeModule),
                data: { userType: SystemType.Client }
            },
            {
                path: 'monitoring',
                loadChildren: () => import('./@features/monitoring/monitoring.module').then(m => m.MonitoringModule)
            },
            {
                path: 'users-management',
                loadChildren: () => import('./@features/users-management/users-management.module').then(m => m.UsersManagementModule)
            },
            {
                path: 'regions',
                loadChildren: () => import('./@features/regions/regions.module').then(m => m.RegionsModule)
            },
            {
                path: 'client-systems',
                loadChildren: () => import('./@features/systems/systems.module').then(m => m.SystemsModule)
            },
            {
                path: 'logs',
                loadChildren: () => import('./@features/logs/logs.module').then(m => m.LogsModule)
            },
            {
                path: 'clients',
                loadChildren: () => import('./@features/clients/clients.module').then(m => m.ClientsModule)
            },
            {
                path: 'partners-management',
                loadChildren: () => import('./@features/partners-management/partners-management.module').then(m => m.PartnersManagementModule)
            },
            {
                path: 'devices-management',
                loadChildren: () => import('./@features/devices-management/devices-management.module').then(m => m.DevicesManagementModule)
            },
            {
                path: 'reports',
                loadChildren: () => import('./@features/reports/reports.module').then(m => m.ReportsModule)
            },
            {
                path: 'profile',
                loadChildren: () => import('./@features/profile/profile.module').then(m => m.ProfileModule)
            },
            {
                path: 'event-types',
                loadChildren: () => import('./@features/event-types/event-types.module').then(m => m.EventTypesModule)
            }
        ],
        canActivate: [LoggedInGuard]
    },
    {
        path: '**',
        redirectTo: ''
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule {}
