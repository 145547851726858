import { getUserType } from 'src/app/@shared/helpers/role';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { ApiService } from 'src/app/@core/services/api/api.service';
import { CrudService } from 'src/app/@core/services/crud/crud.service';

@Injectable({
    providedIn: 'root'
})
export class SystemsService extends CrudService {
    readonly apiUrl: string = `/v1/${getUserType()}/systems`;

    constructor(apiService: ApiService) {
        super(apiService);
    }

    getSystemEvents(systemId: number, eventType: 'ALL' | 'Urgent' | 'ActiveFaults'): Observable<any> {
        return this.apiService.get(`${this.apiUrl}/${systemId}/events?eventType=${eventType}`);
    }

    silent(systemId: number): Observable<any> {
        return this.apiService.post(`${this.apiUrl}/${systemId}/command`, {
            type: 'silence'
        });
    }

    reset(systemId: number): Observable<any> {
        return this.apiService.post(`${this.apiUrl}/${systemId}/command`, {
            type: 'reset'
        });
    }

    getModesAndTimes(): Observable<any> {
        return this.apiService.get(`${this.apiUrl}/mods`);
    }

    setMode(id: string, data: any): Observable<any> {
        return this.apiService.post(`${this.apiUrl}/${id}/changemode`, data);
    }

    updateContacts(id: number, data: any) {
        return this.apiService.put(`${this.apiUrl}/${id}/updatecontacts`, data);
    }
}
